import React, { useState } from 'react';
import Photo from '../Photo';
import Loader from '../Loader';
import images from '../../Data/images.json';
import './style.scss';

const Gallery = () => {
  const [imagesLoaded, setImagesLoaded] = useState(false);

  return (
    <>
      {!imagesLoaded && <div className="h-full items-center flex flex-col flex-grow"><Loader/></div>}

      <ul className={`${imagesLoaded ? 'flex' : 'hidden'} image-gallery`}>
        {images.map((img, i) => (
          <>
            <li key={i}>
              <Photo
                src={img.src}
                title={img.title}
                setImagesLoaded={i === images.length - 1 && setImagesLoaded}
              />
            </li>
          </>
        ))}
      </ul>
    </>
  );
};

export default Gallery;
