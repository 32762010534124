import React from 'react';
import './App.css';
import Gallery from './Components/Gallery';

function App() {

  return (
    <>
      <div className="h-full min-h-screen pb-32 text-center bg-[radial-gradient(ellipse_at_top,_var(--tw-gradient-stops))] from-gray-700 via-gray-900 to-black">
        <header className="py-4 text-white">
          <img src="./images/logo.svg" alt="logo" className="mx-auto pt-9" style={{width: "4em"}}/>
          <h1 className="text-4xl pt-5">Pixel Dust</h1>
          <h2 className="pb-5 pt-1 divide-y-2">Photos by Michelle Reeves</h2>
        </header>
        <div className="w-4/6 mx-auto">
          <Gallery />
        </div>
      </div>
    </>
  );
}

export default App;
