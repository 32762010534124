import React from 'react';

const Photo = ({ src, key, title, setImagesLoaded} ) => {

  function onLoad() {
    setTimeout(() => {
      setImagesLoaded(true);
    }, "1100")
  }

  return (
    <>
      <img
        src={src}
        key={key}
        className={`rounded`}
        onLoad={() => onLoad()}
        alt={title}
      />
      <div className="overlay">
        <span className="font-bold">{title}</span>
      </div>
    </>
  );
};

export default Photo;
